import supabase from "../../../config/supabaseClient";
import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { styles } from "../../../styles";

const CurrentVariety = () => {
  const [latestSurvey, setLatestSurvey] = useState(null);
  const [hybridInfo, setHybridInfo] = useState(null);
  const [indicaInfo, setIndicaInfo] = useState(null);
  const [sativaInfo, setSativaInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase
          .from("misc")
          .select("content")
          .order("created_at", { ascending: false })
          .limit(1)
          .eq("keyword", "Bewertungsumfrage");

        if (error) {
          console.error("Fehler beim Abrufen der Daten:", error);
          return;
        }

        if (data && data.length > 0) {
          setLatestSurvey(data[0].content);

          const hybridData = await fetchPlantInfo(data[0].content.Hybrid);
          setHybridInfo(hybridData);

          const indicaData = await fetchPlantInfo(data[0].content.Indica);
          setIndicaInfo(indicaData);

          const sativaData = await fetchPlantInfo(data[0].content.Sativa);
          setSativaInfo(sativaData);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
      }
    };

    const fetchPlantInfo = async (plantName) => {
      try {
        const { data, error } = await supabase
          .from("misc")
          .select("*")
          .eq("keyword", "Plant");

        const matchingPlant = data.find(
          (item) =>
            item.content && item.content.plantname === plantName
        );

        if (error) {
          console.error(
            "Fehler beim Abrufen der Pflanzeninformationen:",
            error
          );
          return null;
        }

        if (matchingPlant) {
          return matchingPlant.content;
        }

        return null;
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
        return null;
      }
    };

    fetchData();
  }, []);

  if (!latestSurvey || !hybridInfo || !indicaInfo || !sativaInfo) {
    return <p>Lade...</p>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      component={Paper}
      padding={4}
    >
      <Typography style={styles.titleText} component="div">
        Verfügbare Sorten
      </Typography>
      <Box display="flex" justifyContent="space-between" width="100%">
        {[latestSurvey.Hybrid, latestSurvey.Indica, latestSurvey.Sativa].map(
          (plantName, index) => (
            <Card key={index} sx={{ maxWidth: 350 }}>
              <CardMedia
              sx={{ height: 190, width: 190 }}
              image={`../../images/BudPictures/${plantName.replace(
                /\s+/g,
                "-"
              )}.png`}
              title={plantName}
            />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {plantName}
                </Typography>
          {plantName && plantName === latestSurvey?.Hybrid ? (
            <>
              <Typography variant="body2" color="text.secondary">
                Typ: {hybridInfo?.type}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                THC: {hybridInfo?.THC}%
              </Typography>
              <Typography variant="body2" color="text.secondary">
                CBD: {hybridInfo?.CBD}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Effekt: {hybridInfo?.Effect.join(", ")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Geschmack: {hybridInfo?.Taste.join(", ")}
              </Typography>
              {/* Weitere Informationen für Pflanzen hier einfügen */}
            </>
          ) : plantName && plantName === latestSurvey?.Indica ? (
            <>
              <Typography variant="body2" color="text.secondary">
                Typ: {indicaInfo?.type}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                THC: {indicaInfo?.THC}%
              </Typography>
              <Typography variant="body2" color="text.secondary">
                CBD: {indicaInfo?.CBD}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Effekt: {indicaInfo?.Effect.join(", ")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Geschmack: {indicaInfo?.Taste.join(", ")}
              </Typography>
              {/* Weitere Informationen für Pflanzen hier einfügen */}
            </>
          ) : plantName && plantName === latestSurvey?.Sativa ? (
            <>
              <Typography variant="body2" color="text.secondary">
                Typ: {sativaInfo?.type}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                THC: {sativaInfo?.THC}%
              </Typography>
              <Typography variant="body2" color="text.secondary">
                CBD: {sativaInfo?.CBD}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Effekt: {sativaInfo?.Effect.join(", ")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Geschmack: {sativaInfo?.Taste.join(", ")}
              </Typography>
              {/* Weitere Informationen für Pflanzen hier einfügen */}
            </>
          ) : ""}
        </CardContent>
      </Card>
    ))}
  </Box>
</Box>

  );
};

export default CurrentVariety;
