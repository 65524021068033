import React from "react";
import "../../App.css";
import { styles } from "../../styles";


import Box from "@mui/material/Box";

const Footer = () => {
  return (
    <div>
      <nav>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{
            padding: "20px",
            backgroundColor: "#8ec68d",
            color: "black",
            textAlign: "center",
          }}
        >
          <h1 style={{ ...styles.titleText, margin: "0px" }}>&copy;2025 Hemp Heaven Hannover e.V.</h1>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <p style={{ ...styles.normalText, margin: "0px" }}>
              <a href="/" title="Startseite" style={styles.normalText}>Home</a>
            </p>
            <p style={{ ...styles.normalText, margin: "0px" }}>
              <a href="/impressum" title="Impressum" style={styles.normalText}>Impressum</a>
            </p>
            <p style={{ ...styles.normalText, margin: "0px" }}>
              <a href="/datenschutz" title="Datenschutz" style={styles.normalText}>Datenschutz</a>
            </p>
            <p style={{ ...styles.normalText, margin: "0px" }}>
              <a href="/sitemap" title="Sitemap" style={styles.normalText}>Sitemap</a>
            </p>
            {/* <Text style={styles.normalTextWhite}>
              <Link to="/cookies">Cookie-Richtlinien</Link>
            </Text> */}
          </Box>
        </Box>
      </nav>
    </div>
  );
};

export default Footer;
