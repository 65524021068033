import * as React from "react";
import supabase from "../../../config/supabaseClient.js";
import { useState } from "react";
import dayjs from "dayjs";

import Button from "@mui/material/Button";
import { TextField, capitalize } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CreateSurveyDialog = () => {
  const [surveyTitel, setSurveyTitel] = useState("");
  const [surveyType, setSurveyType] = useState("");
  const [surveyStart, setSurveyStart] = useState("");
  const [surveyEnd, setSurveyEnd] = useState("");
  const [questions, setQuestions] = useState([{ question: "", options: [""] }]);
  const [miscSurveyContent, setMiscSurveyContent] = useState({
    Umfragestart: "",
    Umfrageende: "",
    Fragen: [],
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const handleClickOpen = () => {
    //console.log("Dialog wird geöffnet");
    setDialogOpen(true);
    //console.log(dialogOpen);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAddQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      { question: "", options: [""] },
    ]);
  };

  const handleAddOption = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].options.push("");
      return newQuestions;
    });
  };

  const handleRemoveQuestion = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions.splice(questionIndex, 1);
      return newQuestions;
    });
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].options.splice(optionIndex, 1);
      return newQuestions;
    });
  };

  const handleCreateSurvey = async (surveyType, surveyStart, surveyEnd) => {
    if (surveyType === "Sortenumfrage") {
      try {
        // Hole den höchsten Wert der id-Spalte in der Tabelle misc
        const { data: maxIdData, error: maxIdError } = await supabase
          .from("misc")
          .select("id", { order: { by: "id", descending: true }, limit: 1 });

        if (maxIdError) {
          console.error(
            "Fehler beim Abrufen des höchsten ID-Werts:",
            maxIdError.message
          );
          return;
        }

        // Ermittle den nächsten Wert für die id
        const maxIdDataSorted = maxIdData.sort((a, b) => b.id - a.id);

        const nextId =
          maxIdDataSorted && maxIdDataSorted.length > 0
            ? maxIdDataSorted[0].id + 1
            : 1;

        // Hole die neueste Bewertungsumfrage
        const { data: latestSurveyData, error: latestSurveyError } =
          await supabase
            .from("misc")
            .select("content")
            .eq("keyword", "Bewertungsumfrage")
            .order("created_at", { ascending: false })
            .limit(1);

        if (latestSurveyError) {
          console.error(
            "Fehler beim Abrufen der neuesten Bewertungsumfrage:",
            latestSurveyError.message
          );
          return;
        }

        // Extrahiere die Werte von "Hybrid", "Indica" und "Sativa"
        const { Hybrid, Indica, Sativa } = latestSurveyData[0]?.content || {};
        //console.log(Hybrid, Indica, Sativa);

        // Hole alle plantnames aus der Tabelle misc mit dem keyword Plant
        const { data: plants, error: plantsError } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "Plant");

        if (plantsError) {
          console.error(
            "Fehler beim Abrufen der Pflanzen:",
            plantsError.message
          );
          return;
        }

        // Extrahiere plantnames aus den erhaltenen Daten
        const plantnames = plants
          .map((plant) => plant.content?.plantname)
          .filter((plantname) => {
            // Filtere plantnames, die gleich Hybrid, Indica oder Sativa sind
            return (
              plantname !== Hybrid &&
              plantname !== Indica &&
              plantname !== Sativa
            );
          });

        //console.log(plantnames);
        // Erstelle das Objekt für die content-Spalte von surveyType
        const surveyContent = {
          Umfragestart: surveyStart,
          Umfrageende: surveyEnd.format("MM.YYYY"),
          ParticipantsIDHybrid: [],
          ParticipantsIDIndica: [],
          ParticipantsIDSativa: [],
        };

        // Füge leere Einträge für jeden plantname hinzu
        if (plantnames && plantnames.length > 0) {
          plantnames.forEach((plantname) => {
            surveyContent[plantname] = "";
            /* surveyContent[plantname] = Math.floor(Math.random() * 21); */
          });
        }

        // Füge den Eintrag in die Tabelle misc hinzu
        const { error } = await supabase.from("misc").insert(
          [
            {
              id: nextId, // Verwende die berechnete nächste ID
              keyword: surveyType,
              content: surveyContent,
            },
          ],
          { onConflict: ["id"] }
        );

        if (error) {
          console.error(
            "Fehler beim Erstellen der Umfrage:",
            error.message,
            console.log(maxIdData)
          );
          return;
        }

        console.log("Umfrage erfolgreich erstellt!");
      } catch (error) {
        console.error("Allgemeiner Fehler:", error.message);
      }
    } else if (surveyType === "Bewertungsumfrage") {
      try {
        // Hole den höchsten Wert der id-Spalte in der Tabelle misc
        const { data: maxIdData, error: maxIdError } = await supabase
          .from("misc")
          .select("id", { order: { by: "id", descending: true }, limit: 1 });

        if (maxIdError) {
          console.error(
            "Fehler beim Abrufen des höchsten ID-Werts:",
            maxIdError.message
          );
          return;
        }

        // Ermittle den nächsten Wert für die id
        const maxIdDataSorted = maxIdData.sort((a, b) => b.id - a.id);

        const nextId =
          maxIdDataSorted && maxIdDataSorted.length > 0
            ? maxIdDataSorted[0].id + 1
            : 1;

        // Hole die neueste Sortenumfrage
        const { data: latestSurveyData, error: latestSurveyError } =
          await supabase
            .from("misc")
            .select("content")
            .eq("keyword", "Sortenumfrage")
            .order("created_at", { ascending: false })
            .limit(1);

        if (latestSurveyError) {
          console.error(
            "Fehler beim Abrufen der neuesten Sortenumfrage:",
            latestSurveyError.message
          );
          return null;
        }

        if (latestSurveyData && latestSurveyData.length > 0) {
          // Extrahiere die Inhalte der Sortenumfrage
          const surveyContent = latestSurveyData[0]?.content || {};

          // Entferne unwichtige Einträge
          delete surveyContent.Umfragestart;
          delete surveyContent.Umfrageende;
          delete surveyContent.ParticipantsID;

          // Sortiere die Inhalte absteigend
          const sortedSurveyContent = Object.entries(surveyContent)
            .sort(([, a], [, b]) => b - a)
            .reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {});

          //console.log(sortedSurveyContent);

          // Hole alle plantnames aus der Tabelle misc mit dem keyword Plant
          const { data: plants, error: plantsError } = await supabase
            .from("misc")
            .select("content")
            .eq("keyword", "Plant");

          if (plantsError) {
            console.error(
              "Fehler beim Abrufen der Pflanzen:",
              plantsError.message
            );
            return;
          }
          // Erstelle Variablen für die verschiedenen Typen
          let indicaPlant = null;
          let hybridPlant = null;
          let sativaPlant = null;

          // Iteriere durch die Sortenumfrage, beginnend mit dem höchsten Wert
          for (const [plantName, count] of Object.entries(
            sortedSurveyContent
          )) {
            // Finde den entsprechenden Typen (Hybrid, Indica, Sativa) für den aktuellen Pflanzennamen
            const plantType = plants.find(
              (plant) => plant.content?.plantname === plantName
            )?.content?.type;

            // Weise den Pflanzennamen dem entsprechenden Typen zu
            if (plantType === "Indica" && !indicaPlant) {
              indicaPlant = plantName;
            } else if (plantType === "Hybrid" && !hybridPlant) {
              hybridPlant = plantName;
            } else if (plantType === "Sativa" && !sativaPlant) {
              sativaPlant = plantName;
            }

            // Überprüfe, ob für alle Typen ein Pflanzennamen zugeordnet wurde
            if (indicaPlant && hybridPlant && sativaPlant) {
              break;
            }
          }

          // Ausgabe der zugeordneten Pflanzennamen für jeden Typen
          //console.log("Indica:", indicaPlant);
          //console.log("Hybrid:", hybridPlant);
          //console.log("Sativa:", sativaPlant);

          const RatingSurveyContent = {
            Hybrid: hybridPlant,
            Indica: indicaPlant,
            Sativa: sativaPlant,
            Umfragestart: surveyStart,
            Umfrageende: surveyEnd.format("MM.YYYY"),
            ParticipantsIDHybrid: [],
            ParticipantsIDIndica: [],
            ParticipantsIDSativa: [],
            TasteRatingHybrid: "",
            TasteRatingIndica: "",
            TasteRatingSativa: "",
            EffectRatingHybrid: "",
            EffectRatingIndica: "",
            EffectRatingSativa: "",
            ParticipantsHybrid: "",
            ParticipantsIndica: "",
            ParticipantsSativa: "",
          };

          // Füge den Eintrag in die Tabelle misc hinzu
          const { error } = await supabase.from("misc").insert(
            [
              {
                id: nextId, // Verwende die berechnete nächste ID
                keyword: surveyType,
                content: RatingSurveyContent,
              },
            ],
            { onConflict: ["id"] }
          );
        } else {
          console.error("Keine Daten gefunden für die Sortenumfrage.");
          return null;
        }

        console.log("Umfrage erfolgreich erstellt!");
      } catch (error) {
        console.error("Allgemeiner Fehler:", error.message);
      }
    } else if (surveyType === "Sonstige Umfrage") {
      try {
        // Hole den höchsten Wert der id-Spalte in der Tabelle misc
        const { data: maxIdData, error: maxIdError } = await supabase
          .from("misc")
          .select("id", { order: { by: "id", descending: true }, limit: 1 });

        if (maxIdError) {
          console.error(
            "Fehler beim Abrufen des höchsten ID-Werts:",
            maxIdError.message
          );
          return;
        }

        // Ermittle den nächsten Wert für die id
        const maxIdDataSorted = maxIdData.sort((a, b) => b.id - a.id);

        const nextId =
          maxIdDataSorted && maxIdDataSorted.length > 0
            ? maxIdDataSorted[0].id + 1
            : 1;

            const newMiscSurveyContent = {
              Umfragestart: surveyStart,
              Umfrageende: surveyEnd.format("MM.YYYY"),
              Fragen: questions.map((question, questionIndex) => ({
                [`Frage ${questionIndex + 1}`]: question.question,
                Auswahlmoeglichkeiten: question.options.reduce((options, option, optionIndex) => {
                  options.push({ [option]: 0 });
                  return options;
                }, []),
              })),
              ParticipantsID: [],
            };

        setMiscSurveyContent(newMiscSurveyContent);

        // Füge den Eintrag in die Tabelle misc hinzu
        const { error } = await supabase.from("misc").insert(
          [
            {
              id: nextId,
              keyword: surveyType,
              content: newMiscSurveyContent,
            },
          ],
          { onConflict: ["id"] }
        );

        console.log("Umfrage erfolgreich erstellt!");
      } catch (error) {
        console.error("Allgemeiner Fehler:", error.message);
      }
    }
    if (sendEmail) {
      // Funktion zum Versenden der E-Mails
      sendEmailNotifications();
    }

    handleCloseDialog();
  };

  const sendEmailNotifications = async () => {
    // Hier implementierst du den Code zum Versenden von E-Mails an Mitglieder
    // Du kannst dazu Supabase-Funktionen, externe E-Mail-Dienste oder andere Mittel verwenden
    console.log("E-Mail Benachrichtigungen werden versendet...");
  };

  return (
    <React.Fragment>
      <Button
      color="success"
        className="button"
        variant="contained"
        onClick={() => handleClickOpen()}
        sx={{
          "@media screen and (max-width: 600px)": {
            // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
            fontSize: 10,
            // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
          },
        }}
      >
        Umfrage erstellen
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle> Umfrage Erstellen:</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel id="survey-type-label">Umfragenart</InputLabel>
            <Select
              labelId="survey-type-label"
              id="survey-type"
              value={surveyType}
              required
              onChange={(e) => setSurveyType(e.target.value)}
              input={<Input outlined="true" label="Wirkung" />}
              sx={{ width: "250px", margin: 2 }}
            >
              <MenuItem value="Sortenumfrage">Sortenumfrage</MenuItem>
              <MenuItem value="Bewertungsumfrage">Bewertungsumfrage</MenuItem>
              <MenuItem value="Sonstige Umfrage">Sonstige Umfrage</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ m: 1 / 2 }}
                maxWidth={false}
              >
                <DatePicker
                  label="Umfrage Start"
                  slotProps={{
                    textField: { name: "Umfrage Start", id: "Umfrage Start" },
                  }}
                  required
                  format="MM.YYYY"
                  onChange={(e) => {
                    const startDate = dayjs(e);
                    setSurveyStart(startDate.format("MM.YYYY"));
                    //console.log(e)
                    // Setze das Enddatum automatisch um 4 Monate verlängert
                    setSurveyEnd(dayjs(e).add(3, "months"));
                    //console.log(dayjs(surveyEnd));
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ m: 1 / 2 }}
                maxWidth={false}
              >
                <DatePicker
                  label="Umfrage Ende"
                  slotProps={{
                    textField: { name: "Umfrage Ende", id: "Umfrage Ende" },
                  }}
                  value={surveyEnd === "" ? null : dayjs(surveyEnd)} // Initial null value
                  required
                  format="MM.YYYY"
                  onChange={(e) => {
                    const selectedDate = dayjs(e);
                    if (selectedDate.isValid()) {
                      console.log(
                        "Umfrage Ende geändert:",
                        selectedDate.format("MM.YYYY")
                      );
                      setSurveyEnd(selectedDate.format("MM.YYYY"));
                    } else {
                      console.error("Ungültiges Datum ausgewählt");
                    }
                  }}
                  onError={(error) =>
                    console.error("Fehler in DatePicker:", error)
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
            {surveyType === "Sonstige Umfrage" &&
              questions.map((question, questionIndex) => (
                <div key={questionIndex}>
                  <TextField
                    label={`Frage ${questionIndex + 1}`}
                    value={question.question}
                    onChange={(e) => {
                      const newQuestions = [...questions];
                      newQuestions[questionIndex].question = e.target.value;
                      setQuestions(newQuestions);
                    }}
                    sx={{ margin: 1, marginTop: 3 }}
                  />
                  {questionIndex === questions.length - 1 && (
                    <Button
                      onClick={() => handleAddQuestion()}
                      variant="contained"
                      color="success"
                      sx={{ margin: 2, marginTop: 4 }}
                    >
                      +
                    </Button>
                  )}
                  {questionIndex > 0 && (
                    <Button
                      onClick={() => handleRemoveQuestion(questionIndex)}
                      variant="contained"
                      color="error"
                      sx={{
                        marginTop:
                          questionIndex === questions.length - 1 ? 2 : 4,
                        marginLeft:
                          questionIndex === questions.length - 1 ? 0 : 2, // Setzen Sie hier die gewünschte Margin für den Minus-Button
                      }}
                    >
                      -
                    </Button>
                  )}
                  {question.options.map((option, optionIndex) => (
                    <div key={optionIndex}>
                      <TextField
                        label={`Auswahlmöglichkeit ${questionIndex + 1}.${
                          optionIndex + 1
                        }`}
                        value={option}
                        onChange={(e) => {
                          const newQuestions = [...questions];
                          newQuestions[questionIndex].options[optionIndex] =
                            e.target.value;
                          setQuestions(newQuestions);
                        }}
                        sx={{ margin: 1, marginLeft: 2 }}
                      />
                      {optionIndex === question.options.length - 1 && (
                        <Button
                          onClick={() => handleAddOption(questionIndex)}
                          variant="contained"
                          color="success"
                          sx={{ margin: 2 }}
                        >
                          +
                        </Button>
                      )}
                      {optionIndex > 0 && (
                        <Button
                          onClick={() =>
                            handleRemoveOption(questionIndex, optionIndex)
                          }
                          variant="contained"
                          color="error"
                          sx={{
                            margin:
                              optionIndex === question.options.length - 1
                                ? 0
                                : 2,
                          }}
                        >
                          -
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              ))}
              <FormControlLabel control={<Checkbox checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)}/>} label="E-Mail Benachrichtigung an Mitglieder versenden?" />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schließen</Button>
          <Button
            onClick={() =>
              handleCreateSurvey(surveyType, surveyStart, surveyEnd)
            }
          >
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default CreateSurveyDialog;
